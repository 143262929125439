<template>
  <v-container>
    <h1 v-html="$t('search_smp_encashment_title')"></h1>
    <v-row class="row search spacer-md">
      <v-col cols="12" sm="6" md="4" lg="2">
        <search-s-m-p-encashment-organisation ref="search-s-m-p-encashment-organisation" @search="search" />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="2">
        <search-contact ref="search-contact" @search="search" />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="2">
        <search-farm ref="search-farm" @search="search" />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="2">
        <searchContactAttributeAndRelation
          name="searchcardAttributeAndRelation"
          store-name="SearchContactAttributesRelationsStore"
          :show-picker="true"
          ref="search-contact-attributes"
          @search="search"
        />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="2">
        <search-legal-entity ref="search-legal-entity" @search="search" />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="2">
        <search-dairy ref="search-dairy" @search="search" />
      </v-col>
    </v-row>
    <v-btn color="primary" @click="search"><span v-html="$t('smp_encashment_search_masterdata_search')" /></v-btn>
    <v-btn color="secondary" class="margin-left" @click="reset"><span v-html="$t('smp_encashment_search_masterdata_reset')" /></v-btn>
    <v-menu offset-y>
      <template v-slot:activator="{ props }">
        <v-btn class="button-margin-left" id="contracts_read_download_dropdown" color="secondary" v-bind="props" :loading="loadingDownloadBulkChanges">
          <span v-html="$t('smp_encashment_search_download_dropdown')" />
          <v-icon dbmblueprimary right>mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item id="search_smp_encashment_download">
          <v-list-item-title v-html="$t('search_smp_encashment_download')"></v-list-item-title>
        </v-list-item>
        <v-list-item @click="download" id="search_smp_encashment_download_wide">
          <v-list-item-title v-html="$t('search_smp_encashment_download_wide')"></v-list-item-title>
        </v-list-item>
        <v-list-item id="search_smp_encashment_bulk_assign">
          <v-list-item-title v-html="$t('search_smp_encashment_bulk_assign')"></v-list-item-title>
        </v-list-item>
        <v-list-item id="search_smp_encashment_bulk_edit">
          <v-list-item-title v-html="$t('search_smp_encashment_bulk_edit')"></v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-data-table-server
      :headers="fields"
      :items="smpEncashmentItems"
      :loading="loading"
      :items-length="totalElements"
      :multi-sort="true"
      @update:itemsPerPage="routerPushSize"
      @update:page="routerPushPage"
      @update:sort-by="routerPushDTSort"
      :sort-by="toDTSort(query.sort)"
      :page="getDTPageFromRoute(query.page)"
      :items-per-page="query.size"
      density="compact"
    >
      <template v-slot:[`item.edit`]="{ item }">
        <template v-if="item.farmContactId && $privileges.has({ path: '/contacts', permission: 'read' })">
          <router-link
            :to="{
              name: 'contacts_edit',
              params: { id: item.farmContactId, tab: 'encashmentAndInvoices' }
            }"
            class="tableaction link"
          >
            <v-icon dbmblueprimary>mdi-text-box-edit-outline</v-icon>
          </router-link>
        </template>
      </template>

      <template v-slot:[`item.smpEncashmentEntityName`]="{ item }">
        <template v-if="item.smpEncashmentEntityIdent || item.smpEncashmentEntityIdent === 0">
          {{ item.smpEncashmentEntityIdent + ' ' + item.smpEncashmentEntityName }}
        </template>
        <template v-else>
          <span v-html="$t('smp_encashment_search_not_assigned')"></span>
        </template>
      </template>
      <template v-slot:[`item.pfrMin`]="{ item }">
        <template v-if="item.pfrMin">{{ $moment(item.pfrMin).format('MM.YYYY') }}</template>
      </template>
      <template v-slot:[`item.pfrMax`]="{ item }">
        <template v-if="item.pfrMax">{{ $moment(item.pfrMax).format('MM.YYYY') }}</template>
      </template>
      <template v-slot:[`item.farmAgisId`]="{ item }">
        <template v-if="item.farmContactId && $privileges.has({ path: '/contacts', permission: 'read' })">
          <router-link
            :to="{
              name: 'contacts_edit',
              params: { id: item.farmContactId, tab: 'overview' }
            }"
            class="tableaction link"
            >{{ item.farmAgisId }}
          </router-link>
        </template>
        <template v-else> {{ item.farmAgisId }}</template>
        {{ item['farmTypeName' + $getUpLang()] }}
      </template>
      <template v-slot:[`item.farmerAgisId`]="{ item }">
        <template v-if="item.farmerContactId && $privileges.has({ path: '/contacts', permission: 'read' })">
          <router-link
            :to="{
              name: 'contacts_edit',
              params: { id: item.farmerContactId, tab: 'overview' }
            }"
            class="tableaction link"
            >{{ item.farmerAgisId }}
          </router-link>
        </template>
      </template>
      <template v-slot:[`item.farmerZip`]="{ item }">
        {{ (item.farmerZip ? item.farmerZip : '') + ' ' + (item.farmerLocality ? item.farmerLocality : '') }}
      </template>
      <template v-slot:[`item.dairyName`]="{ item }">
        <template v-if="item.dairyContactId && $privileges.has({ path: '/contacts', permission: 'read' })">
          <router-link
            :to="{
              name: 'contacts_edit',
              params: { id: item.dairyContactId, tab: 'overview' }
            }"
            class="tableaction link"
            >{{ item.dairyIdent }}
          </router-link>
          {{ item.dairyName }}
        </template>
        <template v-else>{{ item.dairyIdent }} {{ item.dairyName }}</template>
      </template>
      <template v-slot:[`item.smpEncashmentValidFrom`]="{ item }">
        <template v-if="item.smpEncashmentValidFrom">
          {{ $moment(item.smpEncashmentValidFrom).format('MM.YYYY') }}
        </template>
      </template>
      <template v-slot:[`item.smpEncashmentValidUntil`]="{ item }">
        <template v-if="item.smpEncashmentValidUntil">
          {{ $moment(item.smpEncashmentValidUntil).format('MM.YYYY') }}
        </template>
      </template>
    </v-data-table-server>
  </v-container>
</template>

<script setup lang="ts">
import { ref, reactive, computed, onMounted, watch, getCurrentInstance } from 'vue'
import searchLegalEntity from '@/components/searchCards/searchLegalEntity.vue'
import { useSearchLegalEntityStore } from '@/store/SearchLegalEntityStore'
import searchContact from '@/components/searchCards/searchContact.vue'
import { useSearchContactStore } from '@/store/SearchContactStore'
import searchDairy from '@/components/searchCards/searchDairy.vue'
import { useSearchDairyStore } from '@/store/SearchDairyStore'
import searchFarm from '@/components/searchCards/searchFarm.vue'
import { useSearchFarmStore } from '@/store/SearchFarmStore'
import searchContactAttributeAndRelation from '@/components/searchCards/searchContactAttributeAndRelation.vue'
import SearchSMPEncashmentOrganisation from '@/components/searchCards/searchSmpEncashmentOrganisation.vue'
import { DTSHeaders } from '@/services/BackendService'
import { Term } from '@/services/term'
import { apiURL, emitter } from '@/main'
import { fileCreator, showError } from '@/services/axios'
import { useSearchSmpEncashmentOrganisationStore } from '@/store/SearchSmpEncashmentsStore'
import type { components } from '@/lib/api/v1.d.ts'
type SmpEncashmentSearchDTO = components['schemas']['SmpEncashmentSearchDTO']

const smpEncashmentItems = ref<SmpEncashmentSearchDTO[]>([])
const totalElements = ref(0)
const loading = ref(false)
const loadingDownloadBulkChanges = ref(false)
const { proxy } = getCurrentInstance()

const fields = reactive<DTSHeaders>([
  {
    key: 'edit',
    sortable: false,
    width: '2%'
  },
  {
    title: proxy.$t('smp_encashment_search_encashment_organisation'),
    key: 'smpEncashmentEntityName',
    sortable: true
  },
  {
    title: proxy.$t('smp_encashment_search_mpd1_encashment_min'),
    key: 'pfrMin',
    sortable: true
  },
  {
    title: proxy.$t('smp_encashment_search_mpd1_encashment_max'),
    key: 'pfrMax',
    sortable: true
  },
  {
    title: proxy.$t('smp_encashment_search_agis_id'),
    key: 'farmAgisId',
    sortable: true
  },
  {
    title: proxy.$t('smp_encashment_search_agis_id_p'),
    key: 'farmerAgisId',
    sortable: true
  },
  {
    title: proxy.$t('smp_encashment_search_smp_encashment_entity_target'),
    key: 'smpEncashmentEntityDefaultEntityIdent',
    sortable: true
  },
  {
    title: proxy.$t('smp_encashment_search_name1'),
    key: 'farmerName',
    sortable: false
  },
  {
    title: proxy.$t('smp_encashment_search_name2'),
    key: 'farmerZip',
    sortable: false
  },
  {
    title: proxy.$t('smp_encashment_search_dairy'),
    key: 'dairyName',
    sortable: true
  },
  {
    title: proxy.$t('smp_encashment_search_dairy_type'),
    key: `dairyTypeName${proxy.$route.params.upLang}`,
    sortable: false
  },
  {
    title: proxy.$t('smp_encashment_search_smp_fee'),
    key: `farmSmpContributionName${proxy.$route.params.upLang}`,
    sortable: false
  },
  {
    title: proxy.$t('smp_encashment_search_casalp'),
    key: 'farmCasalp',
    sortable: false
  },
  {
    title: proxy.$t('smp_encashment_search_valid_from'),
    key: 'smpEncashmentValidFrom',
    sortable: false
  },
  {
    title: proxy.$t('smp_encashment_search_valid_until'),
    key: 'smpEncashmentValidUntil',
    sortable: false
  }
])

const searchSmpEncashmentOrganisationStore = useSearchSmpEncashmentOrganisationStore()

const queryParams = computed(() => ({
  smpEncashmentEntityIds: searchSmpEncashmentOrganisationStore.SmpEncashmentEntityIds.join(','),
  smpEncashmentEntityFilter: searchSmpEncashmentOrganisationStore.SmpEncashmentEntityFilter,
  smpContribution: searchSmpEncashmentOrganisationStore.SmpContribution,
  smpEncashmentDate: searchSmpEncashmentOrganisationStore.SmpEncashment_date
}))

const term = computed(() => [
  ...Term.buildTermItems(useSearchContactStore(), useSearchContactStore().items),
  ...Term.buildTermItems(useSearchFarmStore(), useSearchFarmStore().items),
  ...Term.buildTermItems(useSearchLegalEntityStore(), useSearchLegalEntityStore().items),
  ...Term.buildTermItems(useSearchDairyStore(), useSearchDairyStore().items)
])

const query = computed(() => ({
  term: Term.stringify(term.value),
  ...queryParams.value,
  ...proxy.getJavaPageOptions({ sort: ['pfrMin, asc', 'farmerAgisId, asc'] })
}))

const search = async () => {
  proxy.$router.push({ path: proxy.$route.path, query: query.value }).catch(() => {
    load()
  })
}

const load = async () => {
  loading.value = true
  try {
    smpEncashmentItems.value = []
    const response = await proxy.axios.get(apiURL + '/smpEncashments/find', { params: query.value })
    const data: SmpEncashmentSearchDTO[] = response.data.content
    smpEncashmentItems.value = Array.isArray(data) ? data : []
    totalElements.value = response.data.totalElements
  } catch (e) {
    showError(e)
  } finally {
    loading.value = false
  }
}

const download = async () => {
  loadingDownloadBulkChanges.value = true
  try {
    const response = await proxy.axios.get(apiURL + '/smpEncashments/wide/export', {
      params: query.value,
      headers: {
        Accept: 'application/msexcel'
      },
      responseType: 'blob'
    })
    // if loading takes too long
    if ((await response.status) === 204) {
      emitter.emit('openDownloadInfoDialog')
    } else {
      fileCreator(await response, 'abzugZuschlag.xlsx')
    }
  } catch (e) {
    let responseObj = await e.response.data.text()
    showError({ response: { data: JSON.parse(responseObj) } })
  } finally {
    loadingDownloadBulkChanges.value = false
  }
}

const reset = () => {
  searchSmpEncashmentOrganisationStore.$reset()
  proxy.$refs['search-contact'].reset()
  proxy.$refs['search-farm'].reset()
  proxy.$refs['search-contact-attributes'].reset()
  proxy.$refs['search-legal-entity'].reset()
  proxy.$refs['search-dairy'].reset()
}

// const download = () => console.log('download')

watch(
  () => proxy.$route.query,
  () => {
    load()
  },
  { deep: true }
)

onMounted(() => {
  if (proxy.$route.query.size) load()
})
</script>

<style scoped lang="scss"></style>
